function Hero(props) {
  return (
    <div className="hero hero-sm bg-dark">
      <div className="container grid-lg">
        <div className="hero-body text-center">
          <h1>{props.title}</h1>
          <p>{props.body}</p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
