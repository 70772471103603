/**
 * Modal Component
 * @props
 *  - isActive      Boolean
 *  - title         string
 *  - component     React component
 *
 * Parent requires 'handleModalView' function to manage the closing of the modal window
 */

function Modal(props) {
  const closeModalWindow = () => {
    props.handleModalView();
  };

  return (
    <div className={`modal ${props.isActive ? 'active' : ''}`}>
      <div className="modal-overlay"></div>
      <div className="modal-container">
        <div className="modal-header">
          <span className="btn btn-clear float-right" onClick={closeModalWindow} aria-label="Close"></span>
          <div className="modal-title h5">{props.title}</div>
        </div>
        <div className="modal-body">
          {props.component}
          {props.children}
        </div>
        <div className="modal-footer"></div>
      </div>
    </div>
  );
}

export default Modal;
