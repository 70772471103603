import React, { useState, useEffect } from "react";

import { createRegion, getRegions, deleteRegion } from "Shared/utils/APIUtils";
import useCreateForm from "Shared/utils/customHooks";
import { NotifyToast } from 'Shared/components';

function AdminRegions(props) {
  const [ regionsList, setRegionsList ] = useState([]);
  const [ showSuccess, setShowSuccess ] = useState(false);

  const CreateNewRegion = () => {
    const formData = {
      'name': inputs.region,
    };

    const formJSON = JSON.stringify(formData);

    createRegion(formJSON, props.id).then(response => {
      setShowSuccess(true);
      setRegionsList([...regionsList, response]);
    }).catch(error => {
      console.log(error);
    });
  }

  const deleteSelectedRegion = (id) => {
    deleteRegion(id).then(response => {
      setRegionsList(regionsList.filter(item => item.id !== id));
    }).catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    const fetchRegions = () => {
      getRegions(props.id).then(response => {
        setRegionsList(response);
      }).catch(error => {
        console.log(error);
      });
    };

    fetchRegions();
  }, [props.id]);

  const {
    inputs,
    handleInputChange,
    handleSubmit
  } = useCreateForm({region: ''}, CreateNewRegion);

  return (
    <div className="card mt-2">
      <div className="card-body">
        <form className="input-group" onSubmit={handleSubmit}>
          <input
            id="region"
            name="region"
            onChange={handleInputChange}
            value={inputs.region}
            type="text"
            className="form-input input-sm mb-2"
            placeholder="Region name"
          />
          <button className="btn btn-sm" type="submit">Add</button>
        </form>
        <ul className="styled-list">
          {regionsList.map(region => (
            <li key={region.id} className="columns styled-list-item">
              <div className="column">
                {region.name}
              </div>
              <div className="column text-right">
                <div className="popover popover-left">
                  <span className="btn btn-sm btn-error">
                    <i className="icon icon-delete"></i>
                  </span>
                  <div className="popover-container popup-box">
                    <h5 className="popup-box-title">Are you sure?</h5>
                    <span className="btn btn-sm" onClick={() => deleteSelectedRegion(region.id)}>
                      Delete Region
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {showSuccess &&
        <NotifyToast message="Added Region" />
      }
    </div>
  );
}

export default AdminRegions;
