import React, { useState } from "react";
import toast from 'react-hot-toast';

import { AdminRegions, Modal } from "Shared/components";
import { createCountry, deleteCountry, updateCountry } from "Shared/utils/APIUtils";
import useCreateForm from "Shared/utils/customHooks";

function AdminListCountries(props) {
  const [modal, showModal] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ countryID, setCountryID ] = useState('');
  const [ countryName, setCountryName ] = useState('');
  const [ countryCode, setCountryCode ] = useState('');
  const [ showMessage, setShowMessage ] = useState('');
  const [ activeRegion, setActiveRegion ] = useState(null);
  const [ guidelinesFile, setGuidelinesFile ] = useState(null);
  const [ deletedCountryID, setDeletedCountryID ] = useState(null);

  console.log(props.countries)

  const handleGuidelinesInput = (e) => {
    setGuidelinesFile(e.target.files[0]);
  }

  const showEditModal = (id, name, code) => {
    setCountryID(id);
    setCountryName(name);
    setCountryCode(code);
    showModal(!modal);
  }

  const CreateNewCountry = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("countryCode", inputs.countryCode);
    if (guidelinesFile) {
      formData.append('guidelinesFile', guidelinesFile);
    }

    createCountry(formData).then(response => {
      setShowMessage('Added country');
      setIsLoading(false);
      setTimeout(function() { setShowMessage(''); }, 2000);

      props.countries.push(response);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    });
  }

  const updateCountryFile = () => {
    const formData = new FormData();
    formData.append("name", countryName);
    formData.append("countryCode", countryCode);
    formData.append('guidelinesFile', guidelinesFile);

    updateCountry(formData, countryID).then(response => {
      toast.success('Updated Guidelines File');
      showModal(false);
    }).catch(error => {
      console.log(error);
    });
  }

  const toggleActiveRegion = (id) => {
    setActiveRegion(id);
  }

  const deleteSelectedCountry = (id, index) => {
    deleteCountry(id).then(response => {
      setDeletedCountryID(index);
    }).catch(error => {
      console.log(error);
    });
  }

  const {
    inputs,
    handleInputChange,
    handleSubmit
  } = useCreateForm({name: '', countryCode: ''}, CreateNewCountry);

  return (
    <div>
      <form className="card bg-gray" onSubmit={handleSubmit}>
        <div className="card-header">
          <div className="card-subtitle">Add country</div>
        </div>
        <div className="card-body form-horizontal">
          <div className="form-group">
            <div className="col-4 col-sm-12">
              <label className="form-label">Name</label>
            </div>
            <div className="col-8 col-sm-12">
              <input
                id="name"
                name="name"
                onChange={handleInputChange}
                value={inputs.name}
                type="text"
                className="form-input input-sm mb-2"
                placeholder="Country"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-4 col-sm-12">
              <label className="form-label">Country Code</label>
            </div>
            <div className="col-8 col-sm-12">
              <input
                id="countryCode"
                name="countryCode"
                onChange={handleInputChange}
                value={inputs.countryCode}
                type="text"
                className="form-input input-sm"
                placeholder="Country Code"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-4 col-sm-12">
              <label className="form-label">Guidelines file</label>
            </div>
            <div className="col-8 col-sm-12">
              <input
                id="guidelinesFile"
                name="guidelinesFile"
                className="form-input"
                type="file"
                onChange={handleGuidelinesInput}
              />
            </div>
          </div>

          <button className={`btn btn-block btn-sm mt-1 ${isLoading ? 'loading' : ''}`} type="submit">Add</button>
        </div>
      </form>
      {showMessage &&
        <div className="toast toast-success">
          {showMessage}
        </div>
      }
      <div className="styled-list card">
        <div className="card-body">
          {!props.countries.length > 0 &&
            <div className="loading loading-lg"></div>
          }
          {props.countries.map((country, index) => (
            <div className={`styled-list-item ${deletedCountryID === index ? 'is-hidden' : ''}`} key={country.id}>
              <div className="styled-list-content">
                {country.name} ({country.countryCode})
                <div className="btn-group btn-group-block">
                  <button className="btn btn-sm" onClick={() => showEditModal(country.id, country.name, country.countryCode)}>
                    <i className="icon icon-edit"></i>
                  </button>
                  <button className={`btn btn-sm ${activeRegion === country.id ? 'btn-success' : ''}`} onClick={() => toggleActiveRegion(country.id)}>
                    Regions
                  </button>
                  <div className="popover popover-left">
                    <span className="btn btn-sm btn-error">
                      <i className="icon icon-delete"></i>
                    </span>
                    <div className="popover-container popup-box">
                      <h5 className="popup-box-title">Are you sure?</h5>
                      <button className="btn btn-sm btn-error ml-1" onClick={() => deleteSelectedCountry(country.id, index)}>
                        Delete Country
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {activeRegion === country.id ? <AdminRegions id={country.id} /> : null}
            </div>
          ))}
        </div>

      </div>

      <Modal isActive={modal} title="Update Guidelines" handleModalView={showEditModal}>
        <input
          name="guidelinesFile"
          className="form-input"
          type="file"
          onChange={handleGuidelinesInput}
        />
        <button
          className="btn btn-sm"
          onClick={(e) => updateCountryFile()}>
          Update
        </button>
      </Modal>
    </div>
  );
}

export default AdminListCountries;
