function InfoPanel(props) {
  return (
    <div className="empty mt-2 mb-2">
      <div className="empty-icon">
        <i className={`icon icon-3x icon-${props.icon}`}></i>
      </div>
      <p className="empty-title h5">{props.title}</p>
      <p className="empty-subtitle">{props.subTitle}</p>
      <div className="empty-action"></div>
    </div>
  );
}

export default InfoPanel;
