import React, { useState, useEffect } from "react";

import { AdminListCountries } from "Shared/components";
import { getCountries } from "Shared/utils/APIUtils";

function AdminManageOffices(props) {
  const [ countriesList, setCountriesList ] = useState([]);

  const fetchCountries = () => {
    getCountries().then(response => {
      setCountriesList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div>
      <AdminListCountries countries={countriesList} />
    </div>
  );
}

export default AdminManageOffices;
