import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import LogoIcon from './logo-icon.png';

function Login(props) {
  let history = useHistory();
  let location = useLocation();

  const [ hasMessage, setHasMessage ] = useState([]);

  const handleSuccess = (response) => {
    props.userCallback(response);
  };

  const handleFailure = (error) => {
    setHasMessage(error);
    return true;
  };

  useEffect(() => {
    const handleAuthFlow = () => {
      if (props.authenticated) {
        let { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
      }
    }

    handleAuthFlow();
  }, [props.authenticated, history, location.state]);

  return (
    <div className="container grid-lg login-screen">
      <div className="panel login-screen-panel text-center">
        <div className="panel-header text-center">
          <img src={LogoIcon} alt="Rentokil icon" />
          <div className="panel-title h5 mt-10">Rentokil desk booking system</div>
        </div>
        <div className="panel-body">
          <p>In order to use this system you must first login with your company email account.</p>
          <div className="text-center">
            {props.status
              ?
               <div className="loading loading-lg"></div>
               :
              <GoogleLogin
                onError={handleFailure}
                onSuccess={handleSuccess}
                theme="filled_black"
                size="large"
                shape="pill"
                width="300px"
                />
            }
            <br />
          </div>
        </div>
        <div className="panel-footer">
          {hasMessage.length > 0 &&
            <div className="toast toast-error">
              {hasMessage}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Login;
