import { NavLink } from "react-router-dom";

import SiteLogo from "./site-logo.png";

function AppHeader(props) {

  const userLogout = () => {
    props.removeSession();
  };

  const checkAdminLevel = () => {
    if (props.user.admin || props.user.permissionLevel !== 'none') {
      return <NavLink className="header-action-item mr-2 bg-error text-light" to="/admin">Admin</NavLink>;
    }
    return null;
  }

  if (props.authenticated) {
    return (
      <div className="header-branded bg-primary">
        <div className="container grid-lg">
          <header className="navbar">
            <section className="navbar-section">
              <NavLink className="navbar-brand mr-2 text-light" to="/form">
                <img src={SiteLogo} alt="Rentokil Booking System" />
              </NavLink>
              <span className="mr-2">Desk booking system</span>
            </section>
            <section className="navbar-section navbar-menu">
              <div className="input-group input-inline header-links">
                {checkAdminLevel()}
                <div className="header-action-item mr-2">
                  <NavLink to="/user">
                    Account &amp; Bookings
                  </NavLink>
                </div>
                <div className="header-action-item">
                  <span className="header-logout-btn" onClick={userLogout}>
                    Logout
                  </span>
                </div>
              </div>
            </section>
          </header>
        </div>
      </div>
    );
  }
  return '';
}

export default AppHeader;
