import { useState, useEffect } from "react";

import {
  getCountries,
  getRegions,
  getOfficesByRegion,
  setUserPermission
} from "Shared/utils/APIUtils";

import PermissionList from './list';

function UserPermissions(props) {
  const { account } = props;
  const [ countriesList, setCountriesList ] = useState([]);
  const [ regionsList, setRegionsList ] = useState([]);
  const [ officeList, setOfficeList ] = useState([]);
  const [ countryID, setCountryID ] = useState();
  const [ regionID, setRegionID ] = useState();
  const [ officeID, setOfficeID ] = useState();
  const [isLoading, setIsLoading] = useState('');

  // Get the list of countries
  const fetchData = (id) => {
    if (countriesList.length > 0) {
      if (countryID) {
        fetchRegionFromCountry();
      }
    } else {
      getCountries().then(response => {
        setCountriesList(response);
      }).catch(error => {
        console.log(error);
      });
    }

    if (regionID) {
      fetchOfficesFromRegion();
    }
  };

  const fetchRegionFromCountry = () => {
    getRegions(countryID).then(response => {
      setRegionsList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const fetchOfficesFromRegion = () => {
    getOfficesByRegion(regionID).then(response => {
      setOfficeList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const addPermission = (type, id) => {
    const formData = {
      'email': account.user.email,
      'entityKind': type,
      'entityId': id
    };

    const formJson = JSON.stringify(formData);

    setUserPermission(formJson).then(response => {
      setIsLoading(type);

      setTimeout(() => {
        setIsLoading('');
      }, 3000);
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    fetchData();
  }, [countryID, regionID]);

  return (
    <div>
      <div className="panel bg-gray">
        <div className="panel-header text-center">
          <figure className="avatar avatar-lg">
            <img src={account.user.imageUrl} alt="Avatar" />
          </figure>
          <div className="panel-title h5 mt-10">{account.user.name}</div>
        </div>
        <div className="panel-body">
          <div className="tile tile-centered">
            <div className="tile-content">
              <div className="tile-title text-bold">Country</div>
              <div className="tile-subtitle">
                Give the user country editing permissions.
              </div>
              <div>
                <div className="columns">
                  <div className="column col-6">
                    <div className="form-group">
                      <select className="form-select" onChange={e => setCountryID(e.target.value)}>
                        <option value="">Select country...</option>
                        {countriesList.map(country => (
                          <option value={country.id} key={country.id}>{country.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="column col-6">
                    {countryID &&
                      <button className={`btn ${isLoading === 'country' ? 'btn-success' : ''}`} onClick={() => addPermission('country', countryID)}>
                        {isLoading === 'country'
                          ? 'Added'
                          : 'Add permission'
                        }
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile tile-centered mt-2">
            <div className="tile-content">
              <div className="tile-title text-bold">Region</div>
              <div className="tile-subtitle">
                Give the user region editing permissions.
              </div>
              <div>
                {countryID
                  ?
                    <div className="columns">
                      <div className="column col-6">
                        <div className="form-group">
                          <select className="form-select" onChange={e => setRegionID(e.target.value)}>
                            <option value="">Select region...</option>
                            {regionsList.map(region => (
                              <option value={region.id} key={region.id}>{region.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="column col-6">
                        {regionID &&
                          <button className={`btn ${isLoading === 'region' ? 'btn-success' : ''}`} onClick={() => addPermission('region', regionID)}>
                            {isLoading === 'region'
                              ? 'Added'
                              : 'Add permission'
                            }
                          </button>
                        }
                      </div>
                    </div>
                  : <p className="toast toast-warning mt-2">Select a <b>country</b> before assigning permissions.</p>
                }
              </div>
            </div>
          </div>
          <div className="tile tile-centered mt-2">
            <div className="tile-content">
              <div className="tile-title text-bold">Office</div>
              <div className="tile-subtitle">
                Give the user office editing permissions.
              </div>
              <div>
                {regionID
                  ?
                    <div className="columns">
                      <div className="column col-6">
                        <div className="form-group">
                          <select className="form-select" onChange={e => setOfficeID(e.target.value)}>
                            <option value="">Select office...</option>
                            {officeList.map((office, index) => (
                              <option value={office.id} key={office.id}>{office.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="column col-6">
                        {officeID &&
                          <button className={`btn ${isLoading === 'office' ? 'btn-success' : ''}`} onClick={() => addPermission('office', officeID)}>
                            {isLoading === 'office'
                              ? 'Added'
                              : 'Add permission'
                            }
                          </button>
                        }
                      </div>
                    </div>
                  : <p className="toast toast-warning mt-2">Select a <b>region</b> before assigning permissions.</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="panel-footer"></div>
      </div>
      {account.permissions.length > 0 &&
        <div className="card mt-2">
          <div className="card-header">
            <h5>Current Permissions</h5>
          </div>
          <div className="card-body">
            <PermissionList list={account.permissions} />
          </div>
        </div>
      }
    </div>
  );
}

export default UserPermissions;
