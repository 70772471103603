import { useState, useEffect } from "react";

import {
  listDesksByDate,
  listUsersByOfficeDate,
  adminModifyUserBooking,
  deleteSetUserBooking
} from "Shared/utils/APIUtils";

import { NotifyToast } from 'Shared/components';
import toast from 'react-hot-toast';
import style from './bookings.module.scss';

function UserBookings(props) {
  const { office } = props.location.state;
  const [ selectedDate, setDate ] = useState();
  const [ editingID, setEditingID ] = useState(null);
  const [ userBookings, setUserBookings ] = useState([]);
  const [ updatedBookingDate, setUpdatedBookingDate ] = useState(selectedDate);
  const [ dayType, setDayType ] = useState('day');
  const [ deskList, setDeskList ] = useState([]);
  const [ deskID, setDeskID ] = useState('-1');
  const [ hasSaved, setSaved ] = useState(false);
  const [ isLoading, setLoading ] = useState(false);

  const fetchBookings = (date) => {
    setLoading(true);
    if (office) {
      setDate(date);
      listUsersByOfficeDate(office.id, date).then(response => {
        setUserBookings(response);
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        console.log(error);
      });
    } else {
      props.history.push('/admin');
    }
  };

  const removeBooking = (officeID, bookingID) => {
    deleteSetUserBooking(officeID, bookingID).then(response => {
      toast.success('Removed booking');
      const index = userBookings.findIndex(({ id }) => id === bookingID);
      if (index !== -1) {
        setUserBookings([
          ...userBookings.slice(0, index),
          ...userBookings.slice(index + 1)
        ]);
      }
      console.log(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const getDesks = (date) => {
    setUpdatedBookingDate(date);

    listDesksByDate(office.id, date, dayType).then(response => {
      setDeskList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const resetEditingID = () => {
    setEditingID(null);
  };

  const selectedBooking = (index) => {
    setEditingID(index);
    getDesks(selectedDate);
  };

  const modifyBooking = (item, index) => {
    setLoading(true);

    const formData = {
      'deskId': deskID,
      'officeId': office.id,
      'date': updatedBookingDate,
      'dayOption': dayType
    };

    const formJson = JSON.stringify(formData);

    adminModifyUserBooking(office.id, item.id, formJson).then(response => {
      setSaved(true);
      setLoading(false);
      console.log(response);
    }).catch(error => {
      console.log(error);
    });

    setSaved(false);
  };

  const formatDate = (date) => {
    const replaced = date.replaceAll('/', '-');
    return replaced;
  };

  useEffect(() => {}, [editingID, selectedDate, userBookings]);

  return (
    <div>
      <div className="empty">
        <div className="empty-icon">
          <i className="icon icon-search icon-3x"></i>
        </div>
        <p className="empty-title h5">Search bookings</p>
        <p className="empty-subtitle">{office.name}</p>
        <div className="empty-action">
          <div className="input-group">
            <span className="input-group-addon">Date</span>
            <input
              type="date"
              className="form-input"
              onChange={e => fetchBookings(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="card mt-2">
        {isLoading
          ? <div className="loading pt-2 pb-2">Loading</div>
          : null
        }
        {userBookings.length > 0 &&
          <div className="card-body">
            <ul className={style['list']}>
              {userBookings.map((item, index) => (
                <li key={item.id} className={style['item']}>
                  <div className={style['details']}>
                    <button className="btn btn-sm btn-link tooltip tooltip-bottom" data-tooltip={item.user.email}>
                      {item.user.name}
                    </button>
                    <p className={style['type']}>{item.dayOption}</p>
                    <p>{item.desk.name}</p>
                    <div className="btn-group">
                      <span
                        className="btn btn-sm"
                        onClick={e => selectedBooking(index)}>
                        Edit
                      </span>
                      <span
                        className="btn btn-sm btn-error"
                        onClick={e => removeBooking(item.desk.officeId, item.id)}>
                        Delete
                      </span>
                    </div>
                  </div>
                  {editingID === index
                    ?
                      <table className={style['editor']}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="date"
                                className="form-input input-sm"
                                defaultValue={formatDate(item.date)}
                                onChange={e => getDesks(e.target.value)}
                              />
                            </td>
                            <td>
                              <select className="form-select select-sm" onChange={e => setDayType(e.target.value)} defaultValue={dayType}>
                                <option>Please select...</option>
                                <option value="day">All Day</option>
                                <option value="morning">Morning</option>
                                <option value="afternoon">Afternoon</option>
                              </select>
                            </td>
                            <td>
                              <select className="form-select select-sm" onChange={e => setDeskID(e.target.value)} disabled={(updatedBookingDate) ? '' : 'disabled'}>
                                <option>Please select...</option>
                                <option value="-1">Random Desk</option>
                                {deskList.map(desk => (
                                  <option value={desk.id} key={desk.id}>{desk.name}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <div className="btn-group">
                                <span
                                  className={`btn btn-sm btn-success ${isLoading ? 'loading' : ''}`}
                                  onClick={e => modifyBooking(item, index)}>
                                  Save
                                </span>
                                <span
                                  className={`btn btn-sm`}
                                  onClick={e => resetEditingID()}>
                                  Close
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    : null
                  }
                </li>
              ))}
            </ul>
          </div>
        }
      </div>


      {(selectedDate && userBookings.length < 1 && !isLoading) &&
        <div className="toast mt-2">
          No bookings for this date
        </div>
      }

      {hasSaved &&
        <NotifyToast type="success" message="Updated booking" />
      }
    </div>
  );
}

export default UserBookings;
