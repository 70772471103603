import { AdminLocationManager } from 'Shared/components';

function AdminLocations(props) {
  return (
    <div>
      <AdminLocationManager />
    </div>
  );
}

export default AdminLocations;
