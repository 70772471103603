import React, { Fragment, useState, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import history from "Shared/utils/browserHistory";
import { PrivateRoute } from "Shared/utils/PrivateRoute";
import { login, getCurrentUser } from "Shared/utils/APIUtils";
import { ACCESS_TOKEN } from "Shared/utils/constants";

import { Header } from 'Shared/components';
import LoginPage from 'Project/Login';
import BookingForm from 'Project/BookingForm';
import AdminPage from 'Project/Admin';
import UserAccount from 'Project/User';
import OfficeDetails from 'Project/Office';

const App = () => {
  const [ currentUser, setCurrentUser ] = useState([]);
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ appInitiating, setAppInitiating ] = useState(true);

  // Check to see if the user is already authenticated
  const fetchUser = () => {
    getCurrentUser().then(response => {
      setCurrentUser(response);
      setIsAuthenticated(true);
      setIsLoading(false);
      setAppInitiating(false);
    }).catch(error => {
      setIsLoading(false);
      setAppInitiating(false);
      console.log(error);
    });
  };

  // User authentication flow following successful Google login
  const handleUserResponse = (response) => {
    setIsLoading(true);

    login(response.credential).then(userResponse => {
      localStorage.setItem(ACCESS_TOKEN, userResponse.token);
      fetchUser();
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    });
  };

  // Remove the users session
  const removeUserSession = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    fetchUser();
  }, [isLoading]);

  return appInitiating ? <div className="loading loading-lg"></div> : (
    <Fragment>
      <Router history={history}>
        <Header authenticated={isAuthenticated} user={currentUser} removeSession={removeUserSession} />
        <Switch>
          <Redirect exact from="/" to="/form" />
          <Route path='/login' render={(props) => <LoginPage {...props} authenticated={isAuthenticated} userCallback={handleUserResponse} status={isLoading} />} />
          <PrivateRoute path="/form" user={currentUser} authenticated={isAuthenticated} component={BookingForm} />
          <PrivateRoute path="/office/:id" authenticated={isAuthenticated} component={OfficeDetails} />
          <PrivateRoute path="/admin" user={currentUser} authenticated={isAuthenticated} component={AdminPage} />
          <PrivateRoute path="/user" user={currentUser} authenticated={isAuthenticated} component={UserAccount} />
        </Switch>
      </Router>
      <Toaster />
    </Fragment>
  );
}

export default App;
