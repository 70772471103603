import { useState, useEffect } from "react";

import {
  listDesksByOffice,
  createDesk,
  setDeskAvailability,
  disableAllDesks,
  updateDesk,
  deleteDesk
} from "Shared/utils/APIUtils";

import { NotifyToast } from "Shared/components";

function AdminDeskManager(props) {
  const [ deskList, setDeskList ] = useState([]);
  const [ deskName, setDeskName ] = useState('');
  const [ isEnabled, setIsEnabled ] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [defaultDays] = useState([false,false,false,false,false,false,false]);
  const [listDays] = useState(['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']);

  const handleInputChange = (e) => {
    setDeskName(e.target.value);
  }

  const handleCheckedState = (e) => {
    setIsEnabled(e.target.checked);
  }

  const toggleDeskAvailability = (index, id, enabled) => {
    let flipStatus = enabled;
    if (flipStatus === true) {
      flipStatus = false;
    } else {
      flipStatus = true;
    }

    setDeskAvailability(id, flipStatus).then(response => {
      setDeskList(
        deskList.map(item =>
          item.id === id
          ? {...item, enabled : response.enabled}
          : item
        ));

      }).catch(error => {
        console.log(error);
      });
    }

    const handleRestrictedDays = (office, index, value) => {
      setShowMsg(true);
      const officeData = office;

      if (officeData.restrictedDays === null) {
        officeData.restrictedDays = defaultDays;
      }

      officeData.restrictedDays[index] = value;

      const formData = {
        'name': officeData.name,
        'enabled': officeData.enabled,
        'restrictedDays': officeData.restrictedDays
      };

      updateDesk(JSON.stringify(formData), officeData).then(response => {
        setShowMsg(false);
      }).catch(error => {
        console.log(error);
      });

    }

    const disableEveryDesk = () => {
      disableAllDesks(props.office.id).then(response => {
        setDeskList(response);
      }).catch(error => {
        console.log(error);
      });
    }

    const deleteSelectedDesk = (id) => {
      deleteDesk(id).then(response => {
        setDeskList(deskList.filter(item => item.id !== id));
      }).catch(error => {
        console.log(error);
      });
    }

    const addNewDesk = () => {
      const formData = {
        'name': deskName,
        'enabled': isEnabled,
        'restrictedDays': [false,false,false,false,false,false,false]
      };

      createDesk(JSON.stringify(formData), props.office.id).then(response => {
        setDeskList([...deskList, response]);
        setDeskName('');
        setIsEnabled(false);
      }).catch(error => {
        console.log(error);
      });
    }

    useEffect(() => {
      const listDesks = () => {
        listDesksByOffice(props.office.id).then(response => {
          setDeskList(response);
        }).catch(error => {
          console.log(error);
        });
      }

      listDesks();
    }, [props.office.id]);

    return (
      <div>
        <p><b>Office: </b> {props.office.name}</p>

        <div className="card bg-gray">
          <div className="card-header">
            <div className="card-subtitle text-gray">Add new desk</div>
          </div>
          <div className="card-body">
            <div className="form-horizontal">
              <div className="form-group">
                <div className="col-3 col-sm-12">
                  <label className="form-label">Desk Name</label>
                </div>
                <div className="col-9 col-sm-12">
                  <input
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    value={deskName}
                    className="form-input"
                    type="text"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-3 col-sm-12">
                  <label className="form-label">Enable desk</label>
                </div>
                <div className="col-9 col-sm-12">
                  <label className="form-checkbox">
                    <input
                      id="enabled"
                      name="enabled"
                      value={isEnabled}
                      checked={isEnabled}
                      onChange={handleCheckedState}
                      type="checkbox"
                    />
                    <i className="form-icon"></i>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn" onClick={() => addNewDesk()}>Add desk</button>
              </div>
            </div>
          </div>
        </div>

        {deskList.length > 0 &&
          <div>
            <div className="columns mt-2">
              <div className="column col-6 text-bold">Desk</div>
              <div className="column col-3 text-bold">Status</div>
              <div className="column col-3 text-bold"></div>
            </div>

            <ul className="has-no-bullets desk-list">
              {deskList.map((item, index) => (
                <li key={item.id} className="mb-2 mt-2 desk-list-item">
                  <div className="columns mt-2 mb-2">
                    <div className="column col-6">
                      {item.name}
                    </div>
                    <div className="column col-3">
                      {item.enabled
                        ? <span className="btn btn-sm btn-success"><i className="icon icon-check"></i></span>
                        : <span className="btn btn-sm btn-error"><i className="icon icon-cross"></i></span>
                      }
                    </div>
                    <div className="column col-3 text-right">
                      <span className="btn btn-sm" onClick={() => toggleDeskAvailability(index, item.id, item.enabled)}>
                        {item.enabled
                          ? 'Disable'
                          : 'Enable'
                        }
                      </span>
                      <span className="btn btn-sm btn-error ml-2" onClick={() => deleteSelectedDesk(item.id, index)}>
                        <i className="icon icon-delete"></i>
                      </span>
                    </div>
                  </div>
                  {item.enabled &&
                    <div className="desk-list-item-restricted">
                      <div className="text-tiny text-bold">Disallow desk bookings on:</div>
                      {item.restrictedDays === null
                        ?
                          <div className="form-group">
                            {defaultDays.map((value, index) =>
                              <label key={index} className="form-checkbox d-inline-block">
                                <input
                                  type="checkbox"
                                  value={listDays[index]}
                                  name={listDays[index]}
                                  defaultChecked={value}
                                  onChange={e => handleRestrictedDays(item, index, e.target.checked)}
                                />
                                <i className="form-icon"></i> {listDays[index]}
                              </label>
                            )}
                          </div>
                        :
                        <div className="form-group">
                          {item.restrictedDays.map((value, index) =>
                            <label key={index} className="form-checkbox d-inline-block">
                              <input
                                type="checkbox"
                                value={listDays[index]}
                                name={listDays[index]}
                                defaultChecked={value}
                                onChange={e => handleRestrictedDays(item, index, e.target.checked)}
                              />
                              <i className="form-icon"></i> {listDays[index]}
                            </label>
                          )}
                        </div>
                      }
                    </div>
                  }
                </li>
              ))}
            </ul>
          </div>
        }

        {showMsg &&
          <NotifyToast type="success" message="Updated availability" />
        }
      </div>
    );
  }

  export default AdminDeskManager;
