import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ACCESS_TOKEN } from "Shared/utils/constants";
import { getOfficeByID } from "Shared/utils/APIUtils";

function OfficeDetails(props) {
  let { id } = useParams();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ officeData, setOfficeData ] = useState(true);

  useEffect(() => {
    const fetchOffice = () => {
      getOfficeByID(id).then(response => {
        setOfficeData(response);
        setIsLoading(false);
      }).catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    };

    fetchOffice();
  }, [id]);

  return isLoading ? <div className="mt-2 loading loading-lg"></div> : (
    <div>
      <div className="heading-bar">
        <h1 className="heading-bar-title">{officeData.name}</h1>
        <h2 className="heading-bar-subtitle">{officeData.address}</h2>
      </div>
      <div className="container grid-lg">
        <div className="section">
          <div className="columns">
            <div className="column">
              <h5>Options</h5>
              <a
                className="btn btn-sm mt-2"
                href={`/api/file/${officeData.region.country.guidelinesFile}/download?at=${localStorage.getItem(ACCESS_TOKEN)}`}
                target="_blank"
                rel="noreferrer"
              >
                Download Office Guidelines
              </a>
            </div>
            <div className="column">
              <h5>Floorplan</h5>
              <img
                className="fixed-image"
                alt="Office Floorplan"
                src={`/api/file/${officeData.floorPlanFile}/view?at=${localStorage.getItem(ACCESS_TOKEN)}`}
              />
              <a
                className="btn btn-sm btn-link mt-2"
                href={`/api/file/${officeData.floorPlanFile}/download?at=${localStorage.getItem(ACCESS_TOKEN)}`}
                target="_blank"
                rel="noreferrer"
              >
                Download Office Floorplan
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default OfficeDetails;
