import {
  Switch,
  Route,
  useRouteMatch,
  NavLink
} from "react-router-dom";

import { Hero, InfoPanel } from 'Shared/components';
import Bookings from './Bookings';
import Permissions from './Permissions';
import AdminSearch from './Search';
import AdminLocations from './Locations';

function AdminPage(props) {
  let { path, url } = useRouteMatch();

  const checkAuth = () => {
    if (props.user.admin || props.user.permissionLevel !== 'none') {
      return true;
    }
  }

  if (checkAuth()) {
    return (
      <div>
        <Hero title="Admin" body="Manage the booking system" />
        <div className="container grid-lg mt-2 section">
          <div className="columns">
            <div className="column col-md-12 col-4">
              <div className="card">
                <div className="card-header">
                  <div className="card-subtitle card-subtitle text-bold">Administration</div>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      <NavLink exact strict to="/admin">
                        Office Management
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact strict to={`${url}/locations`}>
                        Countries &amp; Regions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${url}/permissions`}>
                        User Permissions
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column col-md-12 col-8">
              <div>
                <Switch>
                  <Route exact path={path} component={AdminSearch} />
                  <Route
                    path={`${path}/permissions`}
                    render={(props) => <Permissions {...props} />}
                  />
                  <Route
                    path={`${path}/bookings`}
                    render={(props) => <Bookings {...props} />}
                  />
                  <Route
                    path={`${path}/locations`}
                    render={(props) => <AdminLocations {...props} />}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <InfoPanel
      icon="cross"
      title="Not authorised"
      subTitle="Account isn't authorised to access this page. Either login with a different account or go back."
    />
  );
}

export default AdminPage;
