import { useState, useEffect } from "react";
import { getFavourites, deleteFavourite, listDesksByDate, createUserBooking, listUsersByOfficeDate } from "Shared/utils/APIUtils";
import { NotifyToast, Modal, InfoPanel, UserList } from 'Shared/components';
import { ACCESS_TOKEN } from "Shared/utils/constants";

function UserFavourites() {
  const [ favouritesList, setFavouritesList ] = useState([]);
  const [ statusMsg, setStatusMsg ] = useState('');
  const [ favouritesData, setFavouritesData ] = useState([]);
  const [ showModal, setShowModal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const toggleModalWindow = () => {
    setShowModal(!showModal);
  };

  const fetchFavourites = () => {
    getFavourites().then(response => {
      setIsLoading(false);
      setFavouritesList(response);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    });
  };

  const newBooking = (office) => {
    setFavouritesData(office);
    setShowModal(true);
  };

  const deleteSelectedFavourite = (officeID, favID) => {
    deleteFavourite(officeID).then(response => {
      setStatusMsg('Favourite deleted');
      setFavouritesList(favouritesList.filter(item => item.id !== favID));
    }).catch(error => {
      setStatusMsg(`Error: ${error.error} ${error.message}`);
    });

    setStatusMsg('');
  };

  useEffect(
    fetchFavourites,
  []);

  return isLoading ? <div className="loading loading-lg"></div> : (
    <div>
      {favouritesList.length > 0
        ?
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Office</th>
                  <th>Location</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {favouritesList.map(item => (
                  <tr key={item.id}>
                    <td>{item.office.name}</td>
                    <td>{item.office.region.country.name} > {item.office.region.name}</td>
                    <td className="text-right">
                      <span className="btn btn-sm mr-2" onClick={() => newBooking(item)}>
                        New Booking
                      </span>
                      <span className="btn btn-sm btn-error btn-action" onClick={() => deleteSelectedFavourite(item.office.id, item.id)}>
                        <i className="icon icon-delete"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {statusMsg &&
              <NotifyToast message={statusMsg} />
            }
          </div>
        : <InfoPanel icon="cross" title="No Favourites" subTitle="These can be added from the desk booking form" />
      }

      {showModal &&
        <Modal
          isActive={showModal}
          handleModalView={toggleModalWindow}
          title="Create booking from favourite"
          component={<CreateBookingModal data={favouritesData} />}
        />
      }
    </div>
  );
}

const CreateBookingModal = (props) => {
  const [ selectedDate, setSelectedDate ] = useState('');
  const [ deskID, setDeskID ] = useState('');
  const [ deskList, setDeskList ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ responseMsg, setResponseMsg ] = useState('');
  const [ userList, setUserList ] = useState([]);

  const fetchDesks = () => {
    listDesksByDate(props.data.office.id, selectedDate).then(response => {
      console.log(response);
      setDeskList(response);
      fetchUsersByOffice();
    }).catch(error => {
      console.log(error);
    });
  };

  const createBooking = () => {
    setIsLoading(true);

    const formData = {
      'deskId': deskID,
      'officeId': props.data.office.id,
      'date': selectedDate
    };

    const formJson = JSON.stringify(formData);

    createUserBooking(formJson).then(response => {
      setIsLoading(false);
      fetchUsersByOffice();
      setResponseMsg('Booking has been confirmed, please check your email for further information.');
    }).catch(error => {
      setIsLoading(false);
      setResponseMsg(error.message);
      console.log(error);
    });
  };

  const fetchUsersByOffice = () => {
    listUsersByOfficeDate(props.data.office.id, selectedDate).then(response => {
      setUserList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(
    fetchDesks,
  [props.data.office.id, selectedDate]);

  return (
    <div>
      <p>Booking desk for the <b>{props.data.office.name}</b> office.</p>
      <div className="form-group">
        <label className="form-label">Date</label>
        <input
          id="date"
          name="date"
          onChange={e => setSelectedDate(e.target.value)}
          className="form-input"
          type="date"
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label className="form-label">Choose Desk</label>
        <select className="form-select" onChange={e => setDeskID(e.target.value)} disabled={!selectedDate}>
          <option>Select desk...</option>
          <option value="-1">Random desk</option>
          {deskList.map(desk => (
            <option value={desk.id} key={desk.id}>{desk.name}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <img className="fixed-image mb-2" src={`/api/file/${props.data.office.floorPlanFile}/view?at=${localStorage.getItem(ACCESS_TOKEN)}`} alt="Floorplan file" />
        {userList.length > 0
          ? <UserList users={userList} account="false" />
          : <InfoPanel icon="cross" title="Office is empty" subTitle="No other colleagues are in the office on this date." />
        }
      </div>
      <div className="form-group">
        <span className={`btn ${isLoading ? 'loading disabled' : ''}`} onClick={() => createBooking()}>
          Create new booking
        </span>
      </div>

      {responseMsg &&
        <div className="toast mt-2 mb-2">
          {responseMsg}
        </div>
      }
    </div>
  );
}

export default UserFavourites;
