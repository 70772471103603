import { useState } from "react";
import { deleteSetUserBooking, adminSwapUserBooking } from "Shared/utils/APIUtils";
import { NotifyToast } from 'Shared/components';

function UserList(props) {
  const [ selectedBooking, setSelectedBooking ] = useState('');
  const [ checkedItems, setCheckedItems ] = useState([]);
  const [ toastMsg, setToastMsg ] = useState();

  console.log(props.users);

  const deleteBooking = (officeID, bookingID) => {
    setSelectedBooking(bookingID);

    deleteSetUserBooking(officeID, bookingID).then(response => {
      setSelectedBooking('');
      props.callback();
    }).catch(error => {
      setSelectedBooking('');
      console.log(error);
    });
  };

  const handleChange = (e) => {
    setToastMsg(null);
    const checked = e.target.checked;
    const selectedValue = e.target.value;
    if (checked) {
      setCheckedItems([...checkedItems, selectedValue]);
    } else {
      var index = checkedItems.indexOf(selectedValue);
      if (index !== -1) {
        checkedItems.splice(index, 1);
      }
    }
  };

  const swapUserBookings = () => {
    if (checkedItems.length === 2) {
      adminSwapUserBooking(checkedItems).then(response => {
        props.callback();
        setToastMsg({type: 'success', message: 'Bookings have been swapped'});
      }).catch(error => {
        console.log(error);
      });
      setToastMsg({type: 'success', message: 'Swapped bookings'});
    } else {
      setToastMsg({type: 'error', message: 'Please select two users to swap bookings'});
    }
  };

  return (
    <div className="card mt-2 mb-2">
      <div className="card-body">
        <h5 className="pt-2 pb-2">Colleagues in the office:</h5>
        <table className="table">
          <thead>
            <tr>
              {props.account.admin &&
                <th></th>
              }
              <th>Desk</th>
              <th>Length</th>
              <th>Colleague</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.users.map((user, key) => (
              <tr key={user.id}>
                {props.account.admin &&
                  <td>
                    <input
                      type="checkbox"
                      value={user.id}
                      onChange={e => handleChange(e)}
                    />
                  </td>
                }
                <td>{user.desk.name}</td>
                <td>{user.dayOption}</td>
                <td>{user.user.name} ({user.user.email})</td>
                <td className="text-right">
                  {props.account.admin &&
                    <span
                      className={`btn btn-sm ${selectedBooking === user.id ? `loading` : ''}`}
                      onClick={() => deleteBooking(user.desk.officeId, user.id)}>
                      Delete Booking
                    </span>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {props.account.admin &&
          <button className="btn mt-2 btn-sm" onClick={swapUserBookings}>
            Swap selected bookings
          </button>
        }
        {toastMsg &&
          <NotifyToast type={toastMsg.type} message={toastMsg.message} />
        }
      </div>
    </div>
    );
  }

  export default UserList;
