import { useState, useEffect } from "react";

import { UserPermissions } from 'Shared/components';
import { getUserPermissions } from "Shared/utils/APIUtils";

function AdminUserPermissions(props) {
  const [ userEmail, setUserEmail ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ userData, setUserData ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState('');

  const searchInputUser = () => {
    setErrorMsg('');
    setUserData('');
    setIsLoading(true);
    getUserPermissions(userEmail).then(response => {
      setIsLoading(false);
      setUserData(response);
    }).catch(error => {
      setIsLoading(false);
      setErrorMsg(error.error);
      console.log(error);
    });
  };

  useEffect(() => {}, [userData]);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="card-title h6">Search for a user to edit their permissions</div>
        </div>
        <div className="card-body">
          <div className="input-group">
            <input
              className="form-input mb-2"
              type="email"
              placeholder="Email Address"
              onChange={e => setUserEmail(e.target.value)}
            />
            <button
              className={`btn input-group-btn ${isLoading ? 'loading' : ''}`}
              onClick={e => searchInputUser()}>
              Search
            </button>
          </div>
        </div>
      </div>

      {userData &&
        <span className="btn mt-2 mb-2" onClick={() => searchInputUser()}>
          <i className="icon icon-refresh"></i> Refresh
        </span>
      }

      {userData && <UserPermissions account={userData} /> }

      {errorMsg &&
        <div className="toast toast-error mt-2">
          {errorMsg}
        </div>
      }
    </div>
  );
}

export default AdminUserPermissions;
