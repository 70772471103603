import { useState } from "react";

import {
  removeUserPermission
} from "Shared/utils/APIUtils";

function UserPermissions(props) {
  const { list } = props;
  const [ selectedID, setSelectedID ] = useState();

  const deletePermission = (entityId) => {
    removeUserPermission(entityId).then(response => {
      setSelectedID(entityId);
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, index) => (
          <tr key={item.id} className={`${selectedID === item.id ? 'deleted-item' : ''}`}>
            <td className="text-capitalize">{item.entityKind}</td>
            <td>{item.entityName}</td>
            <td className="text-right">
              <span className="btn btn-sm btn-error" onClick={() => deletePermission(item.id)}>
                Remove
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UserPermissions;
