import { useState, useEffect } from "react";

import { getUserBookings, adminDeleteUserBooking } from "Shared/utils/APIUtils";
import { InfoPanel, NotifyToast, Hero, UserFavourites } from 'Shared/components';

function UserAccount(props) {
  const [ bookingsList, setBookingsList ] = useState([]);
  const [ notifyUser, setNotifyUser ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const deleteBooking = (id) => {
    setLoading(true);
    adminDeleteUserBooking(id).then(response => {
      setNotifyUser(true);
      setLoading(false);
      setBookingsList([...bookingsList]);
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  };

  useEffect(() => {
    const fetchBookings = () => {
      getUserBookings().then(response => {
        setBookingsList(response);
      }).catch(error => {
        console.log(error);
      });
    };

    fetchBookings();
  }, []);

  return (
    <div>
      <Hero title="Account" body="Manage previous bookings and favourites" />

      <div className="container grid-lg">
        <div className="section">
          <h5 className="section-heading">Favourites</h5>
          <UserFavourites />
        </div>

        <div>
          <h5 className="section-heading">Previous 20 bookings</h5>
          {bookingsList.length > 0
            ?
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Desk</th>
                    <th>Office</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {bookingsList.map(booking => (
                    <tr key={booking.id}>
                      <td>{booking.date}</td>
                      <td>{booking.desk.name}</td>
                      <td>{booking.desk.office.name} > {booking.desk.office.address}</td>
                      <td className="text-right">
                        <span className={`btn btn-sm btn-error ${loading ? `loading` : ''}`} onClick={() => deleteBooking(booking.id)}>
                          Cancel
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            : <InfoPanel icon="mail" title="No previous bookings" subTitle="Bookings will appear after they've been confirmed, please check back if you've recently made a booking" />
          }
        </div>
        {notifyUser &&
          <NotifyToast type="success" message="Booking has been cancelled" />
        }
      </div>
    </div>
  );
}

export default UserAccount;
