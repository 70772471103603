import {useState} from 'react';

/**
* Form hook
* Add to the input:
*  onChange={handleInputChange}
*  value={inputs.<FORM_NAME}
*/
const useCreateForm = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    callback();
  }
  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }
  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
}

export default useCreateForm;
