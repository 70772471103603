import { ACCESS_TOKEN } from "./constants";

const request = (options, type) => {
  const headers = new Headers();

  if (type !== 'file') {
    headers.append(
      'Content-Type', 'application/json'
    );
  }

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

/**
 * User account
 */
export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: "/api/user/me",
    method: "GET"
  });
}

export function login(token) {
  return request({
    url: `/api/user/login?token=${token}`,
    method: 'GET'
  });
}

// List user bookings
export function getUserBookings() {
  return request({
    url: `/api/booking/last/list/100`,
    method: 'GET'
  });
}

// User Favourites
export function setUserFavourite(officeID) {
  return request({
    url: `/api/favourite/${officeID}`,
    method: 'POST'
  });
}

export function getFavourites() {
  return request({
    url: `/api/favourite/list`,
    method: 'GET'
  });
}

export function deleteFavourite(officeID) {
  return request({
    url: `/api/favourite/${officeID}`,
    method: 'DELETE'
  });
}

// User Search

/**
 * Admin requests
 */
// Countries
 export function createCountry(payload) {
   return request({
     url: "/api/country",
     method: 'POST',
     body: payload
   }, 'file');
 }

 export function updateCountry(payload, id) {
   return request({
     url: `/api/country/${id}`,
     method: 'PUT',
     body: payload
   }, 'file');
 }

 export function getCountries() {
   return request({
     url: "/api/country/list/0/100",
     method: 'GET'
   });
 }

 export function deleteCountry(id) {
   return request({
     url: `/api/country/${id}`,
     method: 'DELETE'
   });
 }

// Regions
export function createRegion(payload, id) {
  return request({
    url: `/api/region/new/${id}`,
    method: 'POST',
    body: payload
  });
}

export function getRegions(id) {
  return request({
    url: `/api/region/list/${id}`,
    method: 'GET'
  });
}

export function deleteRegion(id) {
  return request({
    url: `/api/region/${id}`,
    method: 'DELETE'
  });
}

// Offices
export function getOffices() {
  return request({
    url: `/api/office/list/0/50`,
    method: 'GET'
  });
}

export function getOfficeByID(id) {
  return request({
    url: `/api/office/${id}`,
    method: 'GET'
  });
}

export function getOfficesByRegion(id) {
  return request({
    url: `/api/office/list/${id}`,
    method: 'GET'
  });
}

export function createOffice(payload) {
  return request({
    url: `/api/office`,
    method: 'POST',
    body: payload
  }, 'file');
}

export function updateOffice(id, payload) {
  return request({
    url: `/api/office/${id}`,
    method: 'PUT',
    body: payload
  }, 'file');
}

export function deleteOffice(id) {
  return request({
    url: `/api/office/${id}`,
    method: 'DELETE'
  });
}

export function getOfficeFloorPlan(id) {
  return request({
    url: `/api/office/${id}/floor/view`,
    method: 'GET'
  });
}

// Desks
export function listDesksByDate(id, date, dayOption) {
  return request({
    url: `/api/desk/list/free/${id}?date=${date}&dayOption=${dayOption}`,
    method: 'GET'
  });
}

export function listDesksByOffice(id) {
  return request({
    url: `/api/desk/list/all/${id}`,
    method: 'GET'
  });
}

export function createDesk(payload, id) {
  return request({
    url: `/api/desk/${id}`,
    method: 'POST',
    body: payload
  });
}

export function deleteDesk(id) {
  return request({
    url: `/api/desk/${id}`,
    method: 'DELETE'
  });
}

export function updateDesk(formData, officeData) {
  return request({
    url: `/api/desk/${officeData.officeId}/desk/${officeData.id}`,
    method: 'PUT',
    body: formData
  });
}

export function setDeskAvailability(id, enabled) {
  return request({
    url: `/api/desk/${id}/enabled/${enabled}`,
    method: 'GET'
  });
}

export function disableAllDesks(id) {
  return request({
    url: `/api/desk/${id}/desks/enabled/false`,
    method: 'GET'
  });
}

// Users in office
export function listUsersByOfficeDate(id, date) {
  return request({
    url: `/api/booking/list/office/${id}?date=${date}`,
    method: 'GET'
  });
}

// Bookings
export function createUserBooking(payload) {
  return request({
    url: `/api/booking`,
    method: 'POST',
    body: payload
  });
}

export function userDeleteBooking(id) {
  return request({
    url: `/api/booking/${id}`,
    method: 'DELETE'
  });
}

export function adminModifyUserBooking(officeID, bookingID, payload) {
  return request({
    url: `/api/booking/admin/${officeID}/${bookingID}`,
    method: 'PUT',
    body: payload
  });
}

// Admin swap booking
export function adminSwapUserBooking(userIDs) {
  return request({
    url: `/api/booking/admin/swap/${userIDs[0]}/${userIDs[1]}`,
    method: 'GET'
  });
}

// Admin delete user booking
export function adminDeleteUserBooking(id) {
  return request({
    url: `/api/booking/${id}`,
    method: 'DELETE'
  });
}

// Admin delete user booking
export function deleteSetUserBooking(officeID, bookingID) {
  return request({
    url: `/api/booking/admin/${officeID}/${bookingID}`,
    method: 'DELETE'
  });
}

// Admin list bookings by office
export function listBookingsByOffice(officeId, page) {
  return request({
    url: `/api/booking/list/office/${officeId}/${page}/100`,
    method: 'GET'
  });
}


/**
 * Account Permissions
 */

// Search permissions by email address
// Accepts 'email' in postbody
export function getUserPermissions(email) {
  return request({
    url: '/api/permission/list/user',
    method: 'POST',
    body: JSON.stringify({"email": email})
  });
}

export function setUserPermission(data) {
  return request({
    url: '/api/permission',
    method: 'POST',
    body: data
  });
}

export function removeUserPermission(id) {
  return request({
    url: `/api/permission/${id}`,
    method: 'DELETE'
  });
}
