import { useState, useEffect } from "react";

function NotifyToast(props) {
  const [ toastStatus, setToastStatus ] = useState('active');

  useEffect(() => {
    const timer = setTimeout(() => {
      setToastStatus('disabled');
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`toast styled-toast styled-toast-${toastStatus} ${props.type ? `toast-${props.type}` : ''}`}>
      {props.message}
    </div>
  );
}

export default NotifyToast;
