import React from 'react';
import ReactDOM from 'react-dom';
import './Style/main.scss';
import App from './App';
import {GoogleOAuthProvider} from "@react-oauth/google";

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
