import React, { useState, useEffect, useRef, useCallback } from "react";
import { ACCESS_TOKEN } from "Shared/utils/constants";
import DatePicker from 'react-date-picker';

import {
  getCountries,
  getRegions,
  getOfficesByRegion,
  listDesksByDate,
  listUsersByOfficeDate,
  createUserBooking,
  setUserFavourite
} from "Shared/utils/APIUtils";

import { InfoPanel, UserList } from 'Shared/components';

function DeskForm(props) {
  const [ selectedDate, setSelectedDate ] = useState();
  const [ visibleDate, setVisibleDate ] = useState();
  const [ dayOption, setDayOption ] = useState('day');
  const [ enableGuest, setEnableGuest ] = useState(false);
  const [ guestEmail, setGuestEmail ] = useState('');
  const [ daysInAdvance, setDaysInAdvance ] = useState('');
  const [ countriesList, setCountriesList ] = useState([]);
  const [ regionsList, setRegionsList ] = useState([]);
  const [ officeList, setOfficeList ] = useState([]);
  const [ officeID, setOfficeID ] = useState('');
  const [ deskList, setDeskList ] = useState([]);
  const [ desksEnabled, setDesksEnabled ] = useState(false);
  const [ deskID, setDeskID ] = useState('');
  const [ userList, setUserList ] = useState([]);
  const [ confirmedBooking, setConfirmedBooking ] = useState('');
  const [ guidelinesId, setGuidelinesId ] = useState('');
  const [ floorMapFileId, setFloorMapFileId ] = useState('');
  const [ favouriteEnabled, setFavouriteEnabled ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const fetchCountries = () => {
    getCountries().then(response => {
      setCountriesList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const findCountryObjByName = (CountryName) => {
    const countryJson = countriesList;
    var filtered = countryJson.filter(a => a.name === CountryName);
    return filtered;
  }

  const fetchRegion = (id, event) => {
    // Reset the selects if the dropdown changes
    setGuidelinesId('');
    setRegionsList([]);
    setOfficeList([]);

    const getOptionTxt = event.target.options[event.target.selectedIndex].text;
    const fetchCountryByName = findCountryObjByName(getOptionTxt);
    setGuidelinesId(fetchCountryByName[0].guidelinesFile);

    setDesksEnabled(false);
    getRegions(id).then(response => {
      setRegionsList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const fetchUsersByOffice = useCallback((id) => {
    if (selectedDate) {
      listUsersByOfficeDate(id, selectedDate).then(response => {
        setUserList(response);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [selectedDate, setUserList]);

  const fetchOffices = (id, e) => {
    getOfficesByRegion(id).then(response => {
      setOfficeList(response);
      fetchUsersByOffice(id);
    }).catch(error => {
      console.log(error);
    });
  };

  const fetchDesks = (id, index) => {
    setFavouriteEnabled(false);
    setFloorMapFileId(index.target[index.target.selectedIndex].getAttribute('data-file'));
    setOfficeID(id);
    fetchUsersByOffice(id);

    // Set the days in advance state to the office selected.
    let selectedOfficeArray = officeList.find(o => o.name === index.target.options[index.target.selectedIndex].text);
    limitCalendarDays(selectedOfficeArray.daysInAdvance);

    if (selectedDate) {
      listDesksByDate(id, selectedDate, dayOption).then(response => {
        setDeskList(response);
        setDesksEnabled(true);
      }).catch(error => {
        console.log(error);
      });
    }
  };

  // Update in office list when booking length changes
  const updateBookingLength = (value) => {
    setDayOption(value);
    updateDeskList(value);
  };

  const updateDeskList = useCallback((dayType) => {
    if (!dayType) {
      dayType = 'day';
    }
    listDesksByDate(officeID, selectedDate, dayType).then(response => {
      setDeskList(response);
      setDesksEnabled(true);
    }).catch(error => {
      console.log(error);
    });
  }, [officeID, selectedDate]);

  const setUserDate = (date) => {
    var formatDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
    setVisibleDate(date);
    setSelectedDate(formatDate);
  };

  const createBooking = () => {
    setIsLoading(true);

    const formData = {
      'deskId': deskID,
      'officeId': officeID,
      'date': selectedDate,
      'dayOption': dayOption,
      'guestEmail': guestEmail
    };

    const formJson = JSON.stringify(formData);

    createUserBooking(formJson).then(response => {
      setIsLoading(false);
      updateDeskList();
      fetchUsersByOffice(officeID);
      setConfirmedBooking('Booking has been confirmed, please check your email for further information.');
    }).catch(error => {
      setIsLoading(false);
      setConfirmedBooking(error.message);
      console.log(error);
    });
  };

  const setFavourite = () => {
    setUserFavourite(officeID).then(response => {
      setFavouriteEnabled(true);
    }).catch(error => {
      console.log(error);
    });
  };

  const updateInOfficeList = () => {
    updateDeskList();
    fetchUsersByOffice(officeID);
  };

  function limitCalendarDays(days) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    setDaysInAdvance(result);
  };

  // Check to see if the date has changed
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevDate = usePrevious(selectedDate);

  useEffect(() => {
    if (prevDate !== selectedDate && officeList.length > 0) {
      updateDeskList();
      fetchUsersByOffice(officeID);
    }

    fetchCountries();
  }, [userList, selectedDate, prevDate, officeList, updateDeskList, officeID, fetchUsersByOffice, dayOption]);

  return (
    <div className="form-horizontal">
      <div className="form-group">
        <div className="col-3 col-sm-12">
          <label className="form-label">Guest Booking</label>
          <p className="form-input-hint">Is this booking for a guest?</p>
        </div>
        <div className="col-9 col-sm-12">
          <label className="form-switch">
            <input type="checkbox" onChange={e => setEnableGuest(!enableGuest)} />
            <i className="form-icon"></i>
          </label>
          {enableGuest &&
            <input
              className="form-input"
              type="email"
              placeholder="Guest Email Address"
              onChange={e => setGuestEmail(e.target.value)}
            />
          }
        </div>
      </div>
      <div className="form-group">
        <div className="col-3 col-sm-12">
          <label className="form-label">Country</label>
        </div>
        <div className="col-9 col-sm-12">
          <select className="form-select" onChange={e => fetchRegion(e.target.value, e)}>
            <option>Select country...</option>
            {countriesList.map(country => (
              <option value={country.id} key={country.id}>{country.name}</option>
            ))}
          </select>
          {guidelinesId &&
            <a className="btn btn-sm mt-2" href={`/api/file/${guidelinesId}/download?at=${localStorage.getItem(ACCESS_TOKEN)}`} target="_blank" rel="noreferrer">
              Download guidelines for this country
            </a>
          }
        </div>
      </div>

      {regionsList.length > 0 &&
        <div className="form-group">
          <div className="col-3 col-sm-12">
            <label className="form-label">Region</label>
          </div>
          <div className="col-9 col-sm-12">
            <select className="form-select" onChange={e => fetchOffices(e.target.value, e)}>
              <option>Select region...</option>
              {regionsList.map(region => (
                <option value={region.id} key={region.id}>{region.name}</option>
              ))}
            </select>
          </div>
        </div>
      }

      {officeList.length > 0 &&
        <div className="form-group">
          <div className="col-3 col-sm-12">
            <label className="form-label">Office</label>
          </div>
          <div className="col-9 col-sm-12">
            <select className="form-select" onChange={e => fetchDesks(e.target.value, e)}>
              <option>Select office...</option>
              {officeList.map((office, index) => (
                <option data-file={office.floorPlanFile} value={office.id} key={office.id}>{office.name}</option>
              ))}
            </select>
            {officeID &&
              <span className={`btn btn-sm mt-2 ${favouriteEnabled ? 'btn-success' : ''}`} onClick={e => setFavourite()}>
                {favouriteEnabled
                  ? 'Saved'
                  : 'Save as favourite'
                }
              </span>
            }
          </div>
        </div>
      }

      {officeID &&
        <div className="columns">
          <div className="column">
            <div className="form-group">
              <div className="col-6 col-sm-12">
                <label className="form-label">Date</label>
              </div>
              <div className="col-6 col-sm-12">
                <DatePicker
                  onChange={setUserDate}
                  value={visibleDate}
                  clearIcon={null}
                  minDate={new Date()}
                  maxDate={daysInAdvance}
                  locale="en-GB"
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="form-group">
              <div className="col-6 col-sm-12">
                <label className="form-label">Booking Length</label>
              </div>
              <div className="col-6 col-sm-12">
                <select className="form-select" onChange={(e) => updateBookingLength(e.target.value)}>
                  <option selected value="day">All Day</option>
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      }

      {deskList.length < 1 && desksEnabled &&
        <div className="toast toast-error mt-2 mb-2">
          Sorry no desks are available for this date and location.
        </div>
      }

      {selectedDate &&
        <div className="card bg-white mt-2 mb-2">
          <div className="card-header">
            <div className="card-subtitle text-gray">Office information</div>
          </div>
          <div className="card-body">
            {floorMapFileId
              ?
                <div>
                  <img className="fixed-image mb-2" src={`/api/file/${floorMapFileId}/view?at=${localStorage.getItem(ACCESS_TOKEN)}`} alt="Floorplan file" />
                  <a className="btn btn-sm btn-link" href={`/api/file/${floorMapFileId}/download?at=${localStorage.getItem(ACCESS_TOKEN)}`} target="_blank" rel="noreferrer">
                    Download floormap
                  </a>
                </div>
              : 'No floorplan available'
            }

            {userList.length > 0
              ? <UserList users={userList} account={props.account} callback={updateInOfficeList} />
              : <InfoPanel icon="cross" title="Office is empty" subTitle="No other colleagues are in the office on this date." />
            }
          </div>
        </div>
      }

      {deskList.length > 0 &&
        <div>
          <div className="form-group">
            <div className="col-3 col-sm-12">
              <label className="form-label">Choose Desk</label>
            </div>
            <div className="col-9 col-sm-12">
              <select className="form-select" onChange={e => setDeskID(e.target.value)}>
                <option>Select desk...</option>
                <option value="-1">Random desk</option>
                {deskList.map(desk => (
                  <option value={desk.id} key={desk.id}>{desk.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <button className={`btn ${isLoading ? 'loading' : ''}`} onClick={() => createBooking()}>Submit request</button>
          </div>
        </div>
      }

      {confirmedBooking &&
        <div className="toast mt-2">
          Booking has been confirmed, please check your email for further information.
        </div>
      }
    </div>
);
}

export default DeskForm;
