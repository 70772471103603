import { Form, Hero } from 'Shared/components';

function BookingForm(props) {
  return (
    <div>
      <Hero
        title="Book your desk"
        body="Fill out the form below to book your desk or view who's in the office on a particular day."
      />
      <div className="container grid-lg">
        <div className="section">
          <div className="card mb-2 text-left">
            <div className="card-body">
              <Form account={props.user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingForm;
