import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import OfficeForm from './officeForm';
import { AdminDeskManager, Modal, InfoPanel, NotifyToast } from 'Shared/components';

import { getOfficesByRegion, deleteOffice } from "Shared/utils/APIUtils";

function OfficeManagement(props) {
  const [ officeList, setOfficeList ] = useState([]);
  const [ showNotification, setNotification ] = useState(false);
  const [ showEditColumn, setShowEditColumn ] = useState(false);
  const [ editColumnType, setEditColumnType ] = useState('');
  const [ showDeskModal, setShowDeskModal ] = useState(false);
  const [ selectedOffice, setSelectedOffice ] = useState([]);

  const emptyFormData = {
    'address': '',
    'daysInAdvance': 0,
    'description': '',
    'floorPlanFile': '',
    'guidelinesFile': '',
    'name': '',
    'regionId': ''
  }

  const toggleModalWindow = () => {
    setShowEditColumn(!showEditColumn);
  };

  const toggleEditColumn = (type, data) => {
    setShowEditColumn(true);
    setEditColumnType(type);
    setSelectedOffice(data);
  };

  const toggleDeskModalWindow = (office) => {
    setShowDeskModal(!showDeskModal);
    setSelectedOffice(office);
  };

  const deleteSelectedOffice = (id) => {
    deleteOffice(id).then(response => {
      setNotification(true);
      setOfficeList(officeList.filter(item => item.id !== id));
    }).catch(error => {
      console.log(error);
    });
  };

  // When an office has been created update the UI to show the new office.
  const handleCallback = (response) => {
    setShowEditColumn(false);
    setOfficeList([...officeList, response]);
  };

  useEffect(() => {
    getOfficesByRegion(props.regionID).then(response => {
      setOfficeList(response);
    }).catch(error => {
      console.log(error);
    });
  }, [props.regionID]);

  return (
    <div>
      <div className="office-overview card">
        <div className="card-header office-overview-header">
          <button className="office-overview-btn" onClick={() => toggleEditColumn('add', emptyFormData)}>
            Create office
          </button>
        </div>
        <div className="card-body">
          <table className="table">
            <tbody>
              {officeList.map(office => (
                <tr key={office.id}>
                  <td>{office.name}</td>
                  <td className="text-right">
                    <div className="btn-group">
                      <Link
                        className="btn btn-sm mb-2"
                        office={office}
                        to={{
                          pathname: "/admin/bookings",
                          state: {office: office}
                        }}>Bookings</Link>
                      <button className="btn btn-sm mb-2" onClick={() => toggleDeskModalWindow(office)}>Desks</button>
                    </div>
                    <div className="btn-group">
                      <button className="btn btn-sm mb-2 ml-2" onClick={() => toggleEditColumn('update', office)}><i className="icon icon-edit"></i></button>
                      <div className="popover popover-left">
                        <span className="btn btn-sm btn-error">
                          <i className="icon icon-delete"></i>
                        </span>
                        <div className="popover-container popup-box">
                          <h5 className="popup-box-title">Are you sure?</h5>
                          <button className="btn btn-sm btn-error" onClick={() => deleteSelectedOffice(office.id)}>
                            Remove Office
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {officeList.length < 1 && <InfoPanel icon="stop" title="No Offices" subTitle="Click on add office to and a new office" />}

        {showEditColumn &&
          <Modal
            isActive={showEditColumn}
            handleModalView={toggleModalWindow}
            title={`${editColumnType} office`}
            component={<OfficeForm type={editColumnType} region={props.regionID} office={selectedOffice} callback={handleCallback} />}
          />
        }
      </div>

      {showDeskModal &&
        <Modal
          isActive={showDeskModal}
          handleModalView={toggleDeskModalWindow}
          title="Manage desks"
          component={<AdminDeskManager office={selectedOffice} />}
        />
      }

      {showNotification &&
        <NotifyToast type="success" message="Office has been removed" />
      }
    </div>
    );
  }

  export default OfficeManagement;
