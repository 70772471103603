import React, { useState, useEffect, useCallback } from "react";

import useCreateForm from "Shared/utils/customHooks";
import { NotifyToast } from 'Shared/components';
import { createOffice, updateOffice } from "Shared/utils/APIUtils";

function EditOffice(props) {
  const [ floorPlanFile, setFloorPlanFile ] = useState(null);
  const [ selectedRegion, setSelectedRegion ] = useState('');
  const [ enableToast, setEnableToast ] = useState('');
  const [ isLoading, setIsLoading ] = useState('');

  const handleFloorPlanChange = (e) => {
    setFloorPlanFile(e.target.files[0]);
  }

  const addOffice = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("regionId", selectedRegion);
    formData.append("name", inputs.name);
    formData.append("address", inputs.address);
    formData.append("description", inputs.description);
    formData.append("daysInAdvance", inputs.daysInAdvance);
    if (floorPlanFile) {
      formData.append('floorPlanFile', floorPlanFile);
    }

    if (props.type === 'add') {
      createOffice(formData).then(response => {
        setIsLoading(false);
        setEnableToast('Added new office');
        props.callback(response);
      }).catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    } else if (props.type === 'update') {
      updateOffice(props.office.id, formData).then(response => {
        setIsLoading(false);
        setEnableToast('Updated office');
      }).catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    }
  }

  const {
    inputs,
    handleInputChange,
    handleSubmit
  } = useCreateForm({name: '', address: '', description: '', daysInAdvance: ''}, addOffice);

  const loadEditOffice = useCallback(() => {
    if (props.office) {
      if (props.type === 'update') {
        setSelectedRegion(props.office.region.id);
      }
      inputs.name = props.office.name;
      inputs.address = props.office.address;
      inputs.description = props.office.description;
      inputs.daysInAdvance = props.office.daysInAdvance;
    }
  }, [props.office, setSelectedRegion, inputs, props.type]);

  useEffect(() => {
    if (props.type === 'add') {
      setSelectedRegion(props.region);
    }
    loadEditOffice();
  }, [props.office, props.region, props.type]);

  return (
    <div className="card mb-2">
      <form className="card-body" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">Office Name (Required)</label>
          <input
            required
            type="text"
            id="name"
            name="name"
            className="form-input"
            onChange={handleInputChange}
            value={inputs.name}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Address (Required)</label>
          <input
            required
            type="text"
            id="address"
            name="address"
            className="form-input"
            onChange={handleInputChange}
            value={inputs.address}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Description</label>
          <textarea
            className="form-input"
            rows="3"
            id="description"
            name="description"
            onChange={handleInputChange}
            value={inputs.description}></textarea>
        </div>
        <div className="form-group">
          <label className="form-label">Max days in advance</label>
          <input
            id="daysInAdvance"
            name="daysInAdvance"
            className="form-input"
            type="number"
            placeholder="0"
            onChange={handleInputChange}
            value={inputs.daysInAdvance}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Floorplan</label>
          <input
            id="floorPlanFile"
            name="floorPlanFile"
            className="form-input"
            type="file"
            placeholder="Office map image"
            onChange={handleFloorPlanChange}
            value={inputs.floorPlanFile}
          />
          {props.type === 'update' &&
            <img className="mt-2" src={props.office.floorPlanFile} alt="Office Floorplan" />
          }
        </div>
        <div className="form-group">
          <button type="submit" className={`btn mt-2 ${isLoading ? 'loading' : ''}`}>
            {props.type}
          </button>
        </div>
      </form>
      {enableToast &&
        <NotifyToast type="success" message={enableToast} />
      }
    </div>
  );
}

export default EditOffice;
