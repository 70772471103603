import React, { useState, useEffect } from "react";
import { getCountries, getRegions } from "Shared/utils/APIUtils";

import Offices from 'Project/Admin/Offices';

function AdminSearch(props) {
  const [ countriesList, setCountriesList ] = useState([]);
  const [ regionsList, setRegionsList ] = useState([]);
  const [ selectedRegion, setSelectedRegion ] = useState('');

  const fetchRegion = (id) => {
    setRegionsList([]);
    setSelectedRegion('');
    getRegions(id).then(response => {
      setRegionsList(response);
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    getCountries().then(response => {
      setCountriesList(response);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <div>
      <div className="card mb-2">
        <div className="card-header">
          <div className="card-subtitle text-bold">Location</div>
        </div>
        <div className="card-body">
          <div className="columns">
            <div className="column">
              <span className="d-block h6 text-gray">Country</span>
              <select className="form-select" onChange={e => fetchRegion(e.target.value)}>
                <option>Select country...</option>
                {countriesList.map(country => (
                  <option value={country.id} key={country.id}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className="column">
              <span className="d-block h6 text-gray">Region</span>
              <select className="form-select" disabled={regionsList.length < 1} onChange={e => setSelectedRegion(e.target.value)}>
                <option>Select region...</option>
                {regionsList.map(region => (
                  <option value={region.id} key={region.id}>{region.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      {selectedRegion !== ''
        ? <Offices regionID={selectedRegion} />
        : <div className="toast toast-warning mt-2 mb-2">Use the filters above to show office locations.</div>
      }
    </div>
  );
}

export default AdminSearch;
